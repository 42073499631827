import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import {Input} from 'antd'

interface CurrencyInputProps {
    onChange: any;
    rawValue: any;
    inputName: any;
    disabled: any;
    setFocusedInput: any;
    inputValueChanged: any;
}

interface CurrencyInputHandles {
    clearInput: () => void;
}

const CurrencyInput = forwardRef<CurrencyInputHandles, CurrencyInputProps>((props: any, ref) => {
    const placeholder = '$0.00'
    const [input, setInput] = useState('');

    useImperativeHandle(ref, () => ({
        clearInput() {
            setInput(placeholder);
        }
    }));

    const handleChange = (e: any) => {
        console.log(`Handling Change in CurrencyInput`)
        const { value: inputValue } = e.target;
        const rawNumber = inputValue.replace(/[^0-9]/g, '');  // Remove any non-number character
        // props.setRawValue(rawNumber)
        // props.onChange(e)
    };

    const handleFocus = (e: any) => {
        console.log(`Handling focus in CurrencyInput`)
        props.setFocusedInput(props.inputName)
    }

    useEffect(() => {
        console.log(`CurrencyInput rawValue changed: ${props.rawValue}`)
        let formattedNumber: any = placeholder;
        if (props.rawValue !== '') {
            formattedNumber = parseFloat(props.rawValue) / 100;  // Convert to decimal
            formattedNumber = formattedNumber.toLocaleString('en-US', { style: 'currency', currency: 'USD' });  // Convert to currency format
        }
        setInput(formattedNumber);
        props.inputValueChanged(props.inputName, props.rawValue)
    }, [props.rawValue])

    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <Input
                id={props.inputName}
                size={"large"}
                disabled={props.disabled}
                value={input}
                onFocus={handleFocus}
                style={{fontSize: '48px', fontWeight: 'bold', opacity: 1}}
                readOnly
            />
        </div>
    );
});

export default CurrencyInput;
