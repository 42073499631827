import React, {useEffect, useState} from 'react';
import './App.css';
import WebSocketComponent from "./WebSocketComponent";

import { useAuth0 } from "@auth0/auth0-react";
import jwt from 'jwt-decode';
import { ConfigProvider } from "antd";

declare global {
  interface Window {
    heap: any;
  }
}


function App() {

  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const domain = 'tryyew.us.auth0.com';
  const [locations, setLocations] = useState<any>([]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://api.yewfi.com/locations`,
          scope: "read:current_user",
        }
      })
          .then((accessToken) => {
            console.log(`AccessToken: ${accessToken}`)
            if (!accessToken) {
              console.error('No access token returned');
              return;
            }

            try {
              const token: any = jwt(accessToken);
              console.log(JSON.stringify(token));
              const result: string[] = token.permissions.map((permission: string) => {
                const splitPermission: string[] = permission.split(':');
                return splitPermission[1];
              });
              setLocations(result);
            } catch (e) {
              console.error('Error decoding the JWT', e);
            }
          })
          .catch((err) => {
            console.error('Error getting access token', err);
          });
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  console.log(user)


  return (
    <div className="App" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <header className="App-header" style={{ height: '100%', maxHeight: '100%' }}>
          {locations?.length > 0 &&
              <ConfigProvider
                  theme={{
                      token: {
                          // Seed Token
                          colorPrimary: '#7ac968'
                      },
                  }}
              >
              <WebSocketComponent locations={locations}/>
              </ConfigProvider>
          }
      </header>
    </div>
  );
}

export default App;
