import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import {Button, Input, Modal, Progress, Radio, RadioChangeEvent, Spin, Typography} from 'antd'
import CurrencyInput from './CurrencyInput';
import KeyboardReact from 'react-simple-keyboard';
import styles from './WebSocketComponent.module.css';
import { CheckCircleOutlined } from '@ant-design/icons'
import {PaymentsService} from "./services/PaymentsService";

type LoyaltyModalProps = {
    open: boolean
    locationCode: string
    locationName: string
    event: any
    onClose: () => void
}

const LoyaltyModal: React.FC<LoyaltyModalProps> = (props) => {
    const [step, setStep] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [amount, setAmount] = useState<any>(0)
    const amountRef = useRef(amount);
    const currencyInputRef = useRef<any>(null);
    const keyboardChargeRef = useRef<any>();
    const timerRef = useRef<number | null>(null); // Ref to store the timer
    const timeDuration = 10;
    const [timeLeft, setTimeLeft] = useState<number>(timeDuration);  // 5 seconds
    const intervalRef = useRef<number | null>(null);  // Ref to store the interval

    const submitLoyalty = () => {
        const details = {
            'location_code': props.locationCode,
            'location_name': props.locationName,
            'plate': props.event?.event_value,
            'session_id': props?.event.session_id,
            'amount': amountRef.current,
            'event': props.event }
        console.log(`Submitting loyalty payload: ${JSON.stringify(details)}`)
        setLoading(true)
        PaymentsService.processVehicleLoyalty(JSON.stringify(details), setLoading).subscribe((result: any) => {
            console.log(`Added loyalty: ${JSON.stringify(result)}`);
        }, (error: any) => {
            console.log(`Error processing vehicle loyalty`)
            setLoading(false);
            handleClose()
            console.error(error)
        });
        setStep(1)
    }


    const handleModalCancel = () => {
        console.log(`Handling modal cancel`)
        if (step === 0) {
            handleClose()
        }
    }

    const handleClose = () => {
        console.log(`Closing LoyaltyModal`)
        // If the modal is closed manually, clear the timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setTimeLeft(timeDuration);  // Reset the time left to 5 seconds
        setStep(0)
        setAmount(0)
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            currencyInputRef.current?.clearInput();
        }
        props.onClose()
    }


    const keyboardChargeLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardChargeDisplay = {
        '{bksp}': '<',
        '{enter}': 'Add Loyalty'
    }

    const onKeyReleasedLoyalty = useCallback((button: any, e: MouseEvent | undefined) => {
        console.log(button)
        if (button == '{enter}') {
            e?.stopImmediatePropagation()
            submitLoyalty()
        } else if (button == '{bksp}') {
            keyboardChargeRef.current.setCaretPosition(amountRef.current.length)
        }
    }, [keyboardChargeRef.current, amountRef])

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const onChangeInput = (event: any) => {
        const inputVal = event.target.value;
        console.log(`onChangeInput ${inputVal}`)
        setAmount(+inputVal)

        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.setInput(inputVal);
        }
    };

    const onInputValueChanged = (inputName: string, inputValue: any) => {
        console.log(`onInputValueChanged ${inputValue}`)
        keyboardChargeRef?.current?.setInput(inputValue)
    }

    const onChangeAll = (inputs: any) => {
        console.log(`onChangeAll ${JSON.stringify(inputs)}`)
        setAmount(inputs['amount'])
    };

    const steps = [
        <div style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            marginTop: '10px'
        }}>
            <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                Transaction Amount
            </Typography.Text>
            <CurrencyInput
                ref={currencyInputRef}
                inputName={'amount'}
                setFocusedInput={() => { }}
                rawValue={amount}
                disabled={loading}
                onChange={onChangeInput}
                inputValueChanged={onInputValueChanged}
            />
            <div style={{width: '100%', marginTop: '10px', marginBottom: '20px'}}>
                {props.event?.meta?.user && !props.event?.meta?.loyalty_redemptions && props.event?.meta?.unactivated_loyalty_redemptions_available &&
                    <Typography.Text style={{fontSize: '24px', fontWeight: '600'}}>
                        Rewards Not Activated
                    </Typography.Text>
                }
            </div>
            <KeyboardReact
                keyboardRef={r => (keyboardChargeRef.current = r)}
                layout={keyboardChargeLayout} display={keyboardChargeDisplay}
                buttonTheme={keyboardButtonTheme}
                onKeyReleased={onKeyReleasedLoyalty}
                inputName={'amount'}
                onChangeAll={onChangeAll}
            />
        </div>,
        <div style={{ padding: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', verticalAlign: 'middle' }}>
            {loading &&
                <Spin size='large' style={{ marginTop: 75, marginBottom: 75 }} />
            }
            {!loading &&
                <>
                    <CheckCircleOutlined style={{ fontSize: 70, color: '#7ac968' }} />
                    <Typography style={{ fontSize: '24px', marginTop: 20 }}>Loyalty Added</Typography>
                    <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'red'}}>NO PAYMENT HAS BEEN TENDERED!</Typography>
                    <Button style={{ fontSize: '24px', fontWeight: 'bold', height: '80px', marginTop: 70, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}} type='primary' block onClick={handleClose}>Close
                        <Progress
                            type={'circle'}
                            percent={(timeLeft / 10) * 100}
                            strokeColor={'white'}
                            size={25}
                            showInfo={false}
                            style={{
                                position: 'absolute', // Set the position to absolute
                                right: '20px',        // Position it 20px from the right
                                top: '50%',           // Start from the middle vertically
                                transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                            }}
                        />
                    </Button>
                </>
            }
        </div>
    ]

    useEffect(() => {
        console.log(`Time left: ${timeLeft}`)
        if (timeLeft <= 0) {
            handleClose()
        }
    }, [timeLeft])

    useEffect(() => {
        console.log(`Step set to 1`)
        // When step is set to 1, start a 5-second timer to close the modal
        if (step === 1) {
            // timerRef.current = window.setTimeout(() => {
            //     console.log(`Timer set to close in 5 seconds`)
            //     handleClose();
            // }, 5000);

            intervalRef.current = window.setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);

            }, 1000);

        }

        if (step === 0) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            // Cleanup - clear the interval when the component is unmounted or if step changes
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            setTimeLeft(timeDuration);  // Reset the time left to 5 seconds
        }
        return () => {
            // Cleanup - clear the timer when the component is unmounted or if step changes
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            // Cleanup - clear the interval when the component is unmounted or if step changes
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [step]);

    useEffect(() => {
        console.log(`Amount changed! ${amount}`)
        amountRef.current = amount;
    }, [amount])


    return (
        <Modal
            centered
            open={props.open}
            onCancel={handleModalCancel}
            width={'100%'}
            footer={[]}
        >
            {steps[step]}
        </Modal>
    );
};

export default LoyaltyModal;
