import React, {useEffect, useRef, useState} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import axios from 'axios';
import ReactTimeAgo from "react-time-ago";
// @ts-ignore
import Image from "react-graceful-image";
import {
    Select,
    Card,
    Typography,
    List,
    Row,
    Col,
    Spin,
    Button,
    Modal,
    Radio,
    RadioChangeEvent,
    Dropdown,
    MenuProps
} from 'antd';

const {Option} = Select;
const {Title} = Typography;
import styles from './WebSocketComponent.module.css';
import CurrencyInput from "./CurrencyInput";
import {PaymentsService} from "./services/PaymentsService";
import {ExclamationCircleOutlined, CheckCircleOutlined, MoreOutlined, RedoOutlined} from '@ant-design/icons'
import PhoneNumberInput from "./PhoneNumberInput";
import "react-simple-keyboard/build/css/index.css";
import {KeyboardReact} from "react-simple-keyboard";
import SoundService from "./services/SoundService";
import GeneralService from "./services/GeneralService";
import IssueModal from './IssueModal';
import {CommandsService} from "./services/CommandsService";
import LoyaltyModal from "./LoyaltyModal";

type Props = {
    locations: string[];
}

const WebSocketComponent: React.FC<Props> = ({locations}) => {
    const [locationCode, setLocationCode] = useState(locations[0] || '');
    const locationName = 'Ben Franks Restaurant'
    const [image, setImage] = useState<any>(null);
    const [text, setText] = useState<string>('CLEAR');
    const [eventTime, setEventTime] = useState<any>(null);
    const [event, setEvent] = useState<any>([]);
    const eventRef = useRef<any>(null);
    const [blink, setBlink] = useState(false);
    const [chargeModalOpen, setChargeModalOpen] = useState(false);
    const chargeModalOpenRef = useRef<any>(false);
    const [enrollModalOpen, setEnrollModalOpen] = useState(false);
    const enrollModalOpenRef = useRef<any>(false);
    const [processPaymentLoading, setProcessPaymentLoading] = useState(false);
    const [confirmingPhoneNumberLoading, setConfirmingPhoneNumberLoading] = useState(false);
    const [promptNewCard, setPromptNewCard] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState<any>(null);
    const [setupIntent, setSetupIntent] = useState<any>(null);
    const paymentIntentRef = useRef(paymentIntent);
    const setupIntentRef = useRef(setupIntent);
    const currencyInputRef = useRef<any>(null);
    const phoneInputChargeRef = useRef<any>(null);
    const phoneInputEnrollRef = useRef<any>(null);
    const keyboardChargeRef = useRef<any>();
    const keyboardEnrollRef = useRef<any>();
    const [chargeInputs, setChargeInputs] = useState<any>({})
    const [enrollInputs, setEnrollInputs] = useState<any>({})
    const [focusedChargeInput, setFocusedChargeInput] = useState('amount');
    const [focusedEnrollInput, setFocusedEnrollInput] = useState('phone');
    const [credit, setCredit] = useState(0);
    const [issueModalOpen, setIssueModalOpen] = useState(false);
    const [loyaltyModalOpen, setLoyaltyModalOpen] = useState(false);

    const rwsRef = useRef<ReconnectingWebSocket | null>(null);
    const swsRef = useRef<ReconnectingWebSocket | null>(null);
    const pwsRef = useRef<ReconnectingWebSocket | null>(null);
    const [checkInterval, setCheckInterval] = useState<any>(null);
    const lastMessageTimeRWSRef = useRef<Date>(new Date());
    const lastMessageTimePWSRef = useRef<Date>(new Date());
    const lastMessageTimeSWSRef = useRef<Date>(new Date());
    const [connectionsOk, setConnectionsOk] = useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => {startEnrollmentFlow()}} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>Enroll Only</Typography>
                </a>
            ),
            disabled: event?.meta?.user?.payment_methods_available
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <a onClick={() => { setIssueModalOpen(true) }} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>Report Issue</Typography>
                </a>
            )
        },
    ]

    const enrolledItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => {startChargeFlow(true)}} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>New Card & Charge</Typography>
                </a>
            )
        },
        {
            key: '2',
            label: (
                <a onClick={() => {setIssueModalOpen(true)}} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>Report Issue</Typography>
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={() => {setLoyaltyModalOpen(true)}} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>Loyalty Only</Typography>
                </a>
            ),
        },
    ]
    const clearItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => { setIssueModalOpen(true) }} rel={'noopener noreferrer'} >
                    <Typography style={{fontSize: '24px', fontWeight: 'bold', padding: '10px 20px 10px 20px'}}>Report Issue</Typography>
                </a>
            ),
        },
    ]


    const keyboardChargeLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardChargeDisplay = {
        '{bksp}': '<',
        '{enter}': 'Charge'
    }

    const keyboardEnrollLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardEnrollDisplay = {
        '{bksp}': '<',
        '{enter}': 'Enroll'
    }

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const keyboardButtonThemeDisabled = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action_disabled,
            buttons: '{enter}'
        }
    ]

    const creditOptions = [
        {label: 'No Credit', value: 0},
        {label: '$5 Credit', value: 500}
    ]


    useEffect(() => {

        return refresh()


    }, [locationCode]);

    const refresh = () => {
        clearInterval(checkInterval);
        closeRwsConnection()
        closePwsConnection()
        closeSwsConnection();

        fetchLatestEvent();

        openRwsConnection()

        openPwsConnection()

        openSwsConnection()

        // create an interval that checks the last message time and refreshes connection if needed
        const checkIntervalTemp = setInterval(() => {
            const now = new Date();
            // Check the events WS
            const diffInMinutesRWS = (now.getTime() - lastMessageTimeRWSRef.current.getTime()) / (1000 * 60);

            if (diffInMinutesRWS >= 5) { // adjust time as needed
                if (rwsRef.current) {
                    rwsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimeRWSRef.current = new Date();
                }
            }

            // Check the payments WS
            const diffInMinutesPWS = (now.getTime() - lastMessageTimePWSRef.current.getTime()) / (1000 * 60);

            if (diffInMinutesPWS >= 5) { // adjust time as needed
                if (pwsRef.current) {
                    pwsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimePWSRef.current = new Date();
                }
            }

            // Check the setup intent WS
            const diffInMinutesSWS = (now.getTime() - lastMessageTimeSWSRef.current.getTime()) / (1000 * 60);

            if (diffInMinutesSWS >= 5) { // adjust time as needed
                if (swsRef.current) {
                    swsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimeSWSRef.current = new Date();
                }
            }

            setConnectionsOk(
                (rwsRef.current != null && rwsRef.current.readyState === WebSocket.OPEN) &&
                (pwsRef.current != null && pwsRef.current.readyState === WebSocket.OPEN) &&
                (swsRef.current != null && swsRef.current.readyState === WebSocket.OPEN)
            );
        }, 1000 * 5); // check every 5 seconds
        setCheckInterval(checkIntervalTemp);
        // return a cleanup function to clear interval and close connection when component unmounts or locationCode changes
        return () => {
            clearInterval(checkInterval);
            closeRwsConnection()
            closePwsConnection()
            closeSwsConnection()
            setConnectionsOk(false);
        };
    };

    useEffect(() => {
        chargeModalOpenRef.current = chargeModalOpen
        if (!chargeModalOpen) {
            resetChargeModalState()
            setProcessPaymentLoading(false);
            // Reopen RWS connection and fetch latest event
            fetchLatestEvent()
            openRwsConnection()
        } else {
            // Close the RWS connection so it doesn't update while modal is up
            closeRwsConnection()
        }

    }, [chargeModalOpen])


    useEffect(() => {
        enrollModalOpenRef.current = enrollModalOpen
        if (!enrollModalOpen) {
            resetEnrollModalState()
            setProcessPaymentLoading(false);
            // Reopen RWS connection and fetch latest event
            fetchLatestEvent()
            openRwsConnection()
        } else {
            // Close the RWS connection so it doesn't update while modal is up
            closeRwsConnection()
        }

    }, [enrollModalOpen])

    useEffect(() => {
        if (!issueModalOpen) {
            fetchLatestEvent()
            openRwsConnection()
        } else {
            // Close the RWS connection so it doesn't update while modal is up
            closeRwsConnection()
        }

    }, [issueModalOpen])

    useEffect(() => {
        if (!loyaltyModalOpen) {
            fetchLatestEvent()
            openRwsConnection()
        } else {
            // Close the RWS connection so it doesn't update while modal is up
            closeRwsConnection()
        }

    }, [loyaltyModalOpen])

    useEffect(() => {
        if (image) {
            setBlink(true);
            const timer = setTimeout(() => setBlink(false), 10000); // blink for 2 seconds
            return () => clearTimeout(timer);
        }
    }, [image]);

    useEffect(() => {
        paymentIntentRef.current = paymentIntent
        if (paymentIntent?.status == 'succeeded') {
            console.log(`Payment Succeeded!`)
            paymentProcessed()
        } else if (paymentIntent?.last_payment_error) {
            console.log(`Payment Error`)
            paymentError()
        }
    }, [paymentIntent])


    useEffect(() => {
        setupIntentRef.current = setupIntent
        if (setupIntent?.status == 'succeeded') {
            console.log(`Setup Enrollment Succeeded!`)
            setupProcessed()
        } else if (setupIntent?.last_setup_error) {
            console.log(`Setup Error`)
            setupError()
        }
    }, [setupIntent])


    useEffect(() => {
        eventRef.current = event;
    }, [event])

    const handleUserInteraction = () => {
        // Check if sound is playing before trying to cancel
        SoundService.cancel();

        manageEventListeners(false);
    };

    const manageEventListeners = (add: boolean) => {
        const method = add ? 'addEventListener' : 'removeEventListener';
        window[method]('mousedown', handleUserInteraction);
        window[method]('touchstart', handleUserInteraction);
        window[method]('keydown', handleUserInteraction);
    };



    const openRwsConnection = () => {
        const rws = new ReconnectingWebSocket(`wss://as4xm6won4.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}`);
        rwsRef.current = rws;

        rws.addEventListener('message', (message) => {
            if (rwsRef.current == null) {
                return;
            }
            const data = JSON.parse(message.data);
            process_event(data)
        });

        // Update the connection status when the websocket is opened or closed
        rws.addEventListener('open', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        rws.addEventListener('close', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        rws.addEventListener('error', (error) => {
            // Handle error here
            console.log('Event WebSocket error:', error);
            setConnectionsOk(false);
        });
    };

    const closeRwsConnection = () => {
        if (rwsRef.current) {
            rwsRef.current.close();
            rwsRef.current = null;
        }
    };

    const openPwsConnection = () => {
        const pws = new ReconnectingWebSocket(`wss://eyjpur1ao7.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}&product=VEHICLE`);
        pwsRef.current = pws;

        pws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            process_payment_message(data)
        });

        // Update the connection status when the websocket is opened or closed
        pws.addEventListener('open', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        pws.addEventListener('close', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        pws.addEventListener('error', (error) => {
            // Handle error here
            console.log('Payment WebSocket error:', error);
            setConnectionsOk(false);
        });
    };

    const closePwsConnection = () => {
        if (pwsRef.current) {
            pwsRef.current.close();
            pwsRef.current = null;
        }
    };

    const openSwsConnection = () => {
        const sws = new ReconnectingWebSocket(`wss://k4sfhcg6f5.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}`);
        swsRef.current = sws;

        sws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            process_setup_message(data)
        });

        // Update the connection status when the websocket is opened or closed
        sws.addEventListener('open', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        sws.addEventListener('close', () => setConnectionsOk(rwsRef.current !== null && pwsRef.current !== null && swsRef.current !== null));
        sws.addEventListener('error', (error) => {
            // Handle error here
            console.log('SetupIntent WebSocket error:', error);
            setConnectionsOk(false);
        });
    };

    const closeSwsConnection = () => {
        if (swsRef.current) {
            swsRef.current.close();
            swsRef.current = null;
        }
    };

    const fetchLatestEvent = async () => {
        try {
            const response = await axios.get(`${GeneralService.PROD_ENDPOINT}/event/${locationCode}/last/1`);
            const data = response.data;
            process_event(data[0])
        } catch (err) {
            console.error(err);
        }
    };

    const commandRefresh = async () => {
        const payload = {
            'location_code': locationCode
        }
        CommandsService.sendVehicleCommand(JSON.stringify(payload), null).subscribe(
            (data: any) => {
                console.log(`Send Vehicle Command Response: ${JSON.stringify(data)}`);
            },
            (error: any) => {
                console.error(`Error sending vehicle command response: ${error}`);
            }
        );
    }

    const resetChargeModalState = () => {
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            currencyInputRef.current?.clearInput();
            keyboardChargeRef.current.clearInput('phone');
            phoneInputChargeRef.current?.clearInput();
            setCredit(0);
            setFocusedChargeInput('amount')
        }
    }

    const resetEnrollModalState = () => {
        if (keyboardEnrollRef?.current) {
            keyboardEnrollRef.current.clearInput('phone');
            phoneInputEnrollRef.current?.clearInput();
            setCredit(0);
            setFocusedEnrollInput('phone');
        }
    }

    const process_event = async (data: any) => {
        if (event?.keep_alive) {
            lastMessageTimeRWSRef.current = new Date();
            return;
        }
        console.log(JSON.stringify(data))
        if (chargeModalOpenRef.current || enrollModalOpenRef.current) {
            console.log('Received event while modal was open')
            return
        }
        if (data?.location_code != locationCode) {
            console.log(`Received event for the wrong locationCode: ${data?.location_code} != ${locationCode}`)
            return
        }
        setPaymentIntent(null);
        setSetupIntent(null);
        if (data.event_type === 'PAYMENT_ENTER') {
            setImage(data.meta?.vehicle_image);
            setText(data.event_value);
            window.heap.track('payment_enter', { 'plate': data.event_value, 'image': data.meta?.vehicle_image });
            if (data?.meta?.user && !data?.meta?.payment) {
                console.log(`User available, but not payment: ${JSON.stringify(data?.meta?.payment)}`)
                SoundService.play(-1);
                manageEventListeners(true);
            } else {
                console.log(`User and payment not available`)
                handleUserInteraction();
            }
        } else if (data.event_type === 'PAYMENT_EXIT') {
            handleUserInteraction();
            setImage(null);
            setText('CLEAR');
        }
        setEventTime(new Date(data.event_ts));
        lastMessageTimeRWSRef.current = new Date(); // update last message time

        setEvent(data);
    }

    const process_payment_message = async (data: any) => {
        if (data?.keep_alive) {
            lastMessageTimePWSRef.current = new Date();
            return
        }
        console.log(`Received payment message: ${JSON.stringify(data)}`)
        lastMessageTimePWSRef.current = new Date();
        const paymentIntentObj = data?.data?.object
        console.log(`Received PaymentIntent ${paymentIntentObj?.metadata?.session_id} vs ${eventRef?.current?.session_id}`)
        if (paymentIntentObj?.object == 'payment_intent' && paymentIntentObj?.metadata?.session_id == eventRef?.current?.session_id) {
            // Make sure the new PaymentIntent is newer
            if (paymentIntentObj?.created > paymentIntentRef?.current?.created || paymentIntentObj?.status == 'succeeded' || paymentIntentObj?.last_payment_error) {
                setPaymentIntent(paymentIntentObj);
                console.log(`Received a status update for the recent processed payment: ${paymentIntentObj?.status}`)
            } else {
                console.log(`Unable to update PaymentIntent: ${paymentIntentObj?.created} vs ${paymentIntentRef?.current?.created}`)
            }
        }
    }

    const process_setup_message = async (data: any) => {
        if (data?.keep_alive) {
            lastMessageTimeSWSRef.current = new Date();
            return
        }
        console.log(`Received setup message: ${JSON.stringify(data)}`)
        lastMessageTimeSWSRef.current = new Date();
        const setupIntentObj = data?.data?.object
        console.log(`Received SetupIntent ${setupIntentObj?.metadata?.session_id} vs ${eventRef?.current?.session_id}`)
        if (setupIntentObj?.object == 'setup_intent' && setupIntentObj?.metadata?.session_id == eventRef?.current?.session_id) {
            // Make sure the new SetupIntent is newer
            if (setupIntentObj?.created > setupIntentRef?.current?.created || setupIntentObj?.status == 'succeeded') {
                setSetupIntent(setupIntentObj);
                console.log(`Received a status update for the recent setup intent: ${setupIntentObj?.status}`)
            }
        }
    }

    function paymentProcessed() {
        setProcessPaymentLoading(false);
        setTimeout(() => {
            setChargeModalOpen(false);
            setEnrollModalOpen(false);
        }, 3000);
    }

    function setupProcessed() {
        setProcessPaymentLoading(false);
        setTimeout(() => {
            setChargeModalOpen(false);
            setEnrollModalOpen(false);
        }, 3000);
    }

    function paymentError() {
        setProcessPaymentLoading(false);
    }

    function setupError() {
        setProcessPaymentLoading(false);
    }

    function startEnrollmentFlow() {
        console.log(`Starting Enrollment Flow`)
        setFocusedEnrollInput('phone');
        setEnrollModalOpen(true);
    }

    function startChargeFlow(newCard: boolean = false) {
        console.log(`Starting Charge Flow`)
        setPromptNewCard(newCard);
        setFocusedChargeInput('amount');
        setChargeModalOpen(true);
    }

    function charge() {
        setProcessPaymentLoading(true);
        const details = {
            'amount': getChargeInputValue('amount'),
            'credit': credit,
            'location_code': locationCode,
            'location_name': locationName,
            'plate': event.event_value,
            'session_id': event.session_id,
            'phone_number': getChargeInputValue('phone').slice(0, 10),
            'new_card': promptNewCard
        }
        console.log(`Charging ${details.amount} to location ${details.location_code}, vehicle plate ${details.plate}, phone ${details.phone_number}, credit ${details.credit}, new_card ${details.new_card}`);
        PaymentsService.processVehiclePayment(JSON.stringify(details), null).subscribe(
            (data: any) => {
                console.log(`Process Vehicle Payment Response: ${JSON.stringify(data)}`);
                console.log(`Setting PaymentIntent ${data?.id}`)
                setPaymentIntent(data)
            },
            (error: any) => {
                console.error(`Error processing vehicle payment: ${error}`);
                setProcessPaymentLoading(false);
            }
        );
    }

    function enroll() {
        setProcessPaymentLoading(true);
        const details = {
            'credit': credit,
            'location_code': locationCode,
            'location_name': locationName,
            'plate': event.event_value,
            'session_id': event.session_id,
            'phone_number': getEnrollInputValue('phone').slice(0, 10)
        }
        console.log(`Enrolling to location ${details.location_code}, vehicle plate ${details.plate}, phone ${details.phone_number}, credit ${details.credit}`);
        PaymentsService.enrollVehicle(JSON.stringify(details), null).subscribe(
            (data: any) => {
                console.log(`Enroll Vehicle Response: ${JSON.stringify(data)}`);
                console.log(`Setting SetupIntent ${data?.id}`)
                setSetupIntent(data)
            },
            (error: any) => {
                console.error(`Error enrolling vehicle: ${error}`);
                setProcessPaymentLoading(false);
            }
        );
    }

    function formatAmount(amountString: any) {
        const amountInCents = parseInt(amountString, 10);
        const amountInDollars = amountInCents / 100;
        return `$${amountInDollars.toFixed(2)}`;
    }

    const onKeyReleasedCharge = (button: any) => {
        if (button == '{enter}' && canCharge()) {
            if (+getChargeInputValue('amount') >= 10000) {
                Modal.confirm({
                    title: 'Are you sure you want to charge this amount?',
                    content: (<Typography>Charge this customer <b>{formatAmount(getChargeInputValue('amount'))}</b></Typography>),
                    width:'100%',
                    onOk: () => {
                        charge();
                    },
                    onCancel: () => {
                        console.log('Charge canceled.');
                    },
                });
            } else {
                charge();
            }
        } else if (button == '{bksp}') {
            console.log(`Backspace pressed!`)
        }
        console.log(`Setting caret position to ${getChargeInputValue(focusedChargeInput).length}`)
        keyboardChargeRef.current.setCaretPosition(getChargeInputValue(focusedChargeInput).length)
    };

    const onKeyReleasedEnroll = (button: any) => {
        if (button == '{enter}' && canEnroll()) {
            enroll();
        } else if (button == '{bksp}') {
            keyboardEnrollRef.current.setCaretPosition(getEnrollInputValue(focusedEnrollInput).length)
        }
    };

    const getChargeInputValue = (inputName: string) => {
        return chargeInputs[inputName] || "";
    };

    const getEnrollInputValue = (inputName: string) => {
        return enrollInputs[inputName] || "";
    };

    const onChangeAllCharge = (inputs: any) => {
        setChargeInputs({...inputs});
    };

    const onChangeAllEnroll = (inputs: any) => {
        setEnrollInputs({...inputs});
    };

    const onChargeInputValueChanged = (inputName: string, inputValue: any) => {
        if (focusedChargeInput == inputName) {
            keyboardChargeRef?.current?.setInput(inputValue)
        }
    }

    const onEnrollInputValueChanged = (inputName: string, inputValue: any) => {
        if (focusedEnrollInput == inputName) {
            keyboardEnrollRef?.current?.setInput(inputValue)
        }
    }


    const onChangeChargeInput = (event: any) => {
        const inputVal = event.target.value;

        setChargeInputs({
            ...chargeInputs,
            [focusedChargeInput]: inputVal
        });
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.setInput(inputVal);
        }
    };

    const onChangeEnrollInput = (event: any) => {
        const inputVal = event.target.value;

        setEnrollInputs({
            ...enrollInputs,
            [focusedEnrollInput]: inputVal
        });
        if (keyboardEnrollRef?.current) {
            keyboardEnrollRef.current.setInput(inputVal);
        }
    };

    const onChangeCredit = ({ target: { value } }: RadioChangeEvent) => {
        console.log('Credit Changed', value);
        setCredit(value);
    };

    function canCharge() {
        return !(processPaymentLoading || getChargeInputValue('amount') < 50 || getChargeInputValue('amount') > 50000 || paymentIntent?.status == 'succeeded' ||
            (!event?.meta?.user && getChargeInputValue('phone').length < 10))
    }

    function canEnroll() {
        return !processPaymentLoading && !event?.meta?.user && getEnrollInputValue('phone').length == 10 && setupIntent?.status != 'succeeded'
    }


    return (
        <div style={{height: '100%', maxHeight: '100%', width: '95%', display: 'flex', flexDirection: 'column'}}>
            <Row style={{
                flex: '0 0 15%',
                maxHeight: '15%',
                overflow: 'auto',
                paddingTop: '20px',
                paddingBottom: '10px'
            }}>
                <div style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%'
                }}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {event?.event_type == 'PAYMENT_ENTER' && event?.meta?.user?.payment_methods_available &&
                            <Dropdown menu={{items: enrolledItems}}>
                                <MoreOutlined style={{ fontSize: 24, paddingLeft: '10px', paddingRight: '30px', color: '#374151' }} />
                            </Dropdown>
                        }
                        {event?.event_type == 'PAYMENT_ENTER' && !event?.meta?.user?.payment_methods_available &&
                            <Dropdown menu={{items}}>
                                <MoreOutlined style={{ fontSize: 24, paddingLeft: '10px', paddingRight: '30px', color: '#374151' }} />
                            </Dropdown>
                        }
                        {event?.event_type != 'PAYMENT_ENTER' &&
                            <Dropdown menu={{items: clearItems}}>
                                <MoreOutlined style={{ fontSize: 24, paddingLeft: '10px', paddingRight: '30px', color: '#374151' }} />
                            </Dropdown>
                        }
                        <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            {locations.length > 1 &&
                                <Select
                                    defaultValue={locationCode}
                                    style={{width: '100%'}}
                                    onChange={(value: any) => setLocationCode(value)}
                                >
                                    {
                                        locations.map((location) =>
                                            <Option value={location} key={location}>{location}</Option>
                                        )
                                    }
                                </Select>
                            }
                            <div style={{
                                height: '20px',
                                width: '20px',
                                borderRadius: '50%',
                                backgroundColor: connectionsOk ? '#7ac968' : 'red',
                                marginLeft: '30px',
                                marginRight: '20px'
                            }} onClick={() => refresh()}/>
                        </div>
                    </div>
                    <div style={{
                        marginTop: '10px',
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'grid',
                        placeItems: 'center'
                    }}>
                        {event?.event_type == 'PAYMENT_ENTER' && !event?.meta.eligible &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: 'red'}}>
                                UNREADABLE PLATE
                            </Typography.Text>
                        }
                        {event?.event_type == 'PAYMENT_ENTER' && event?.meta.eligible && event?.meta?.user?.payment_methods_available &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#7ac968'}}>
                               YEWPAY ENROLLED
                            </Typography.Text>
                        }
                        {event?.event_type == 'PAYMENT_ENTER' && event?.meta.eligible && event?.meta?.user && !event?.meta?.user?.payment_methods_available &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: 'red'}}>
                                PROMPT NEW CARD
                            </Typography.Text>
                        }
                        {event?.event_type == 'PAYMENT_ENTER' && event?.meta.eligible && !event?.meta.user &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#374151'}}>
                                NOT ENROLLED
                            </Typography.Text>
                        }
                        {event?.event_type != 'PAYMENT_ENTER' &&
                            <Typography.Text style={{fontSize: '24px', fontWeight: '600', color: '#374151'}}>
                                NO VEHICLE PRESENT
                            </Typography.Text>
                        }
                    </div>
                </div>
            </Row>
            <Row style={{
                flex: '0 0 50%',
                maxHeight: '50%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '10px'
            }}>
                <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                    {image
                        ? <div className={`${styles.imageContainer} ${blink ? styles.blink : ''}`}
                               style={{
                                   position: 'relative',
                                   display: 'flex',
                                   width: 'calc(100% - 20px)',
                                   alignItems: 'center',
                                   justifyContent: 'center',
                                   alignContent: 'center'
                               }}>
                            <Image src={image}
                                   alt='Vehicle'
                                   style={{maxWidth: '100%', width: '100%', height: 'auto', borderRadius: '8px'}}
                            />
                            {!event?.meta?.user?.payment_methods_available && event?.meta?.month_visits >= 4 &&
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 5,
                                    color: 'red',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    textShadow: '2px 2px 0 #ffffff, -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff'
                                }}>
                                    REGULAR
                                </div>
                            }
                            <div style={{position: 'absolute', right: '20px', top: '20px'}}>
                                <Button type="primary" shape="circle" icon={<RedoOutlined/>} size={'large'}
                                        onClick={() => commandRefresh()}/>
                            </div>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 5,
                                color: 'red',
                                fontSize: '32px',
                                fontWeight: 'bold',
                                textShadow: '2px 2px 0 #ffffff, -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff'
                            }}>
                                {event?.event_value}
                            </div>
                        </div>
                        : <div style={{
                            width: 'calc(100% - 20px)',
                            height: 'calc(100% - 20px)',
                            backgroundColor: 'grey',
                            borderRadius: '8px',
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                            <h1 style={{color: 'white', fontWeight: 'bold'}}>
                                CLEAR
                            </h1>
                            <div style={{position: 'absolute', right: '20px', top: '20px'}}>
                                <Button type="primary" shape="circle" icon={<RedoOutlined/>} size={'large'}
                                        onClick={() => commandRefresh()}/>
                            </div>
                        </div>
                    }
                </div>
            </Row>
            <Row style={{
                flex: '0 0 35%',
                maxHeight: '35%',
                overflow: 'auto',
                paddingTop: '10px',
                paddingBottom: '20px'
            }}>
                {event?.event_type == 'PAYMENT_ENTER' &&
                    <Card style={{width: '100%'}} bodyStyle={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}>
                        <Button style={{fontSize: '24px', fontWeight: 'bold', height: '80px'}} type={'primary'}
                                onClick={() => startChargeFlow()}
                                block
                                loading={processPaymentLoading}
                                disabled={event?.meta?.payment?.payment_status == 'succeeded' || !event?.meta?.eligible}>
                            {event?.meta?.eligible ? (event?.meta?.user?.payment_methods_available ? 'CHARGE' : 'ENROLL & CHARGE') : 'UNREADABLE'}
                        </Button>
                        <div style={{
                            marginTop: '10px',
                            flexGrow: 1,
                            overflow: 'auto',
                            display: 'grid',
                            placeItems: 'center'
                        }}>
                            <Typography.Text
                                type={event?.meta?.payment?.payment_status == 'succeeded' ? 'success' : 'danger'}
                                style={{fontSize: '36px', fontWeight: 'bold'}}>
                                {event?.meta?.payment?.payment_status == 'succeeded' ? 'PAID' : 'NOT PAID'}
                            </Typography.Text>
                        </div>
                    </Card>
                }
            </Row>
            <Modal
                centered
                open={chargeModalOpen}
                onOk={() => setChargeModalOpen(false)}
                onCancel={() => setChargeModalOpen(false)}
                width={'100%'}
                footer={[]}
            >
                <div style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    marginTop: '10px'
                }}>
                    <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                        Transaction Amount
                    </Typography.Text>
                    <CurrencyInput
                        ref={currencyInputRef}
                        inputName={'amount'}
                        setFocusedInput={setFocusedChargeInput}
                        rawValue={getChargeInputValue('amount')}
                        disabled={processPaymentLoading || paymentIntentRef?.current?.status == 'succeeded'}
                        onChange={onChangeChargeInput}
                        inputValueChanged={onChargeInputValueChanged}
                    />
                    {!event?.meta?.user &&
                        <div style={{marginTop: '10px'}}>
                            <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                                Phone Number
                            </Typography.Text>
                            <PhoneNumberInput
                                ref={phoneInputChargeRef}
                                inputName={'phone'}
                                setFocusedInput={setFocusedChargeInput}
                                rawValue={getChargeInputValue('phone')}
                                disabled={processPaymentLoading || paymentIntentRef?.current?.status == 'succeeded'}
                                onChange={onChangeChargeInput}
                            />
                        </div>
                    }
                    <div style={{
                        marginTop: '10px',
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'grid',
                        placeItems: 'center',
                        height: '20vh'
                    }}>
                        {paymentIntent?.status == 'requires_payment_method' && !paymentIntent?.last_payment_error &&
                            <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                                Insert Card Into Terminal
                            </Typography.Text>
                        }
                        {paymentIntent?.last_payment_error &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <ExclamationCircleOutlined
                                    style={{color: 'red', fontSize: '48px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: 'red'}}>
                                    Payment Error
                                </Typography.Text>
                                <Typography.Text style={{fontSize: '24px', color: 'red'}}>
                                    {paymentIntent?.last_payment_error?.message}
                                </Typography.Text>
                            </div>
                        }
                        {paymentIntent?.status == 'succeeded' &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <CheckCircleOutlined style={{color: '#7ac968', fontSize: '48px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: '#7ac968'}}>
                                    Payment Approved
                                </Typography.Text>
                            </div>
                        }
                    </div>
                    {!event?.meta?.user &&
                        <div style={{width: '100%', marginBottom: '20px'}}>
                            <Radio.Group className={styles.radioGroup} optionType={'button'} onChange={onChangeCredit}
                                         value={credit} style={{width: '100%'}} size={'large'} buttonStyle={'solid'}>
                                {creditOptions.map((option, index) => (
                                    <Radio.Button key={index} value={option.value} className={styles.radioButton}>
                                        {option.label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </div>
                    }
                    {event?.meta?.user && !event?.meta?.loyalty_redemptions && event?.meta?.unactivated_loyalty_redemptions_available &&
                        <Typography.Text style={{fontSize: '24px', fontWeight: '600', marginBottom: '20px'}}>
                            Rewards Not Activated
                        </Typography.Text>
                    }
                    {event?.meta?.promo &&
                        <div style={{width: '100%', marginBottom: '10px', textAlign: 'center'}}>
                            <Typography.Text type={'success'} style={{fontSize: '18px', fontWeight: '600'}}>PROMO WILL APPLY</Typography.Text>
                        </div>
                    }
                    <KeyboardReact
                        keyboardRef={r => (keyboardChargeRef.current = r)}
                        layout={keyboardChargeLayout} display={keyboardChargeDisplay}
                        buttonTheme={canCharge() ? keyboardButtonTheme : keyboardButtonThemeDisabled}
                        onKeyReleased={onKeyReleasedCharge}
                        inputName={focusedChargeInput}
                        onChangeAll={onChangeAllCharge}
                    />
                </div>
            </Modal>
            <Modal
                centered
                open={enrollModalOpen}
                onOk={() => setEnrollModalOpen(false)}
                onCancel={() => setEnrollModalOpen(false)}
                width={'100%'}
                footer={[]}
            >
                <div style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    marginTop: '10px'
                }}>
                    <div style={{marginTop: '10px'}}>
                        <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                            Phone Number
                        </Typography.Text>
                        <PhoneNumberInput
                            ref={phoneInputEnrollRef}
                            inputName={'phone'}
                            setFocusedInput={setFocusedEnrollInput}
                            rawValue={getEnrollInputValue('phone')}
                            disabled={processPaymentLoading || setupIntentRef?.current?.status == 'succeeded'}
                            onChange={onChangeEnrollInput}
                        />
                    </div>
                    <div style={{
                        marginTop: '10px',
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'grid',
                        placeItems: 'center',
                        height: '20vh'
                    }}>
                        {setupIntent?.status == 'requires_payment_method' && !setupIntent?.last_setup_error &&
                            <Typography.Text style={{fontSize: '30px', fontWeight: 'bold'}}>
                                Insert Card Into Terminal
                            </Typography.Text>
                        }
                        {setupIntent?.last_setup_error &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <ExclamationCircleOutlined
                                    style={{color: 'red', fontSize: '48px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: 'red'}}>
                                    Setup Error
                                </Typography.Text>
                                <Typography.Text style={{fontSize: '24px', color: 'red'}}>
                                    {setupIntent?.last_setup_error?.code}
                                </Typography.Text>
                            </div>
                        }
                        {setupIntent?.status == 'succeeded' &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '100%',
                                height: '100%'
                            }}>
                                <CheckCircleOutlined style={{color: '#7ac968', fontSize: '48px', paddingBottom: '20px'}}/>
                                <Typography.Text style={{fontSize: '30px', fontWeight: 'bold', color: '#7ac968'}}>
                                    Vehicle Enrolled
                                </Typography.Text>
                            </div>
                        }
                    </div>
                    {!event?.meta?.user &&
                        <div style={{width: '100%', marginBottom: '20px'}}>
                            <Radio.Group className={styles.radioGroup} optionType={'button'} onChange={onChangeCredit}
                                         value={credit} style={{width: '100%'}} size={'large'} buttonStyle={'solid'}>
                                {creditOptions.map((option, index) => (
                                    <Radio.Button key={index} value={option.value} className={styles.radioButton}>
                                        {option.label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </div>
                    }
                    <KeyboardReact
                        keyboardRef={r => (keyboardEnrollRef.current = r)}
                        layout={keyboardEnrollLayout} display={keyboardEnrollDisplay}
                        buttonTheme={canEnroll() ? keyboardButtonTheme : keyboardButtonThemeDisabled}
                        onKeyReleased={onKeyReleasedEnroll}
                        inputName={focusedEnrollInput}
                        onChangeAll={onChangeAllEnroll}
                    />
                </div>
            </Modal>
            <IssueModal event={event ? event : {}} locationCode={locationCode} open={issueModalOpen} onClose={() => {setIssueModalOpen(false)}}/>
            <LoyaltyModal event={event ? event : {}} locationCode={locationCode} open={loyaltyModalOpen} onClose={() => {setLoyaltyModalOpen(false)}} locationName={locationName}/>
        </div>
    );
};

export default WebSocketComponent;
