import soundFile from '../assets/beep.mp3';

class SoundService {
    private audio: HTMLAudioElement;
    private playCount: number;
    private repeatTimes: number;

    constructor() {
        this.audio = new Audio(soundFile);
        this.playCount = 0;
        this.repeatTimes = 0;
        // Bind the replay method to the instance of this class
        this.replay = this.replay.bind(this);
        this.audio.addEventListener('ended', this.replay);
    }

    replay() {
        // Check if the sound has been played less than n times or if it should play indefinitely
        if (this.repeatTimes === -1 || this.playCount < this.repeatTimes - 1) {
            this.playCount++;
            this.audio.currentTime = 0; // Reset the audio to start
            this.audio.play();
        } else {
            this.playCount = 0; // Reset the counter after reaching the repeatTimes
        }
    }

    play(times: number) {
        this.playCount = 0;
        this.repeatTimes = times;
        this.audio.loop = times === -1;
        this.audio.play();
    }

    cancel() {
        this.audio.pause(); // Pause the audio
        this.audio.currentTime = 0; // Reset the audio to start
        this.audio.loop = false; // Make sure to turn off looping in case it was on
    }
}

export default new SoundService();
