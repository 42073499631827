import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en.json'
import { Auth0Provider } from '@auth0/auth0-react';

TimeAgo.addDefaultLocale(en)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="tryyew.us.auth0.com"
      clientId="iR8aG95bX4jkci0uU7Fk2S1IeXgLKdoQ"
      authorizationParams={{
          redirect_uri: window.location.origin,
          audience: `https://api.yewfi.com/locations`,
          scope: "read:current_user",
      }}
    >
      <App />
    </Auth0Provider >
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
