import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import {Button, Input, Modal, Progress, Spin, Typography} from 'antd'
import { SupportService } from './services/SupportService';
import CurrencyInput from './CurrencyInput';
import KeyboardReact from 'react-simple-keyboard';
import styles from './WebSocketComponent.module.css';
import { CheckCircleOutlined } from '@ant-design/icons'

type IssueModalProps = {
    open: boolean
    locationCode: string
    event: any
    onClose: () => void
}

const IssueModal: React.FC<IssueModalProps> = (props) => {
    const [step, setStep] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [amount, setAmount] = useState<any>({ amount: 0 })
    const currencyInputRef = useRef<any>(null);
    const keyboardChargeRef = useRef<any>();
    const timerRef = useRef<number | null>(null); // Ref to store the timer
    const [timeLeft, setTimeLeft] = useState<number>(5);  // 5 seconds
    const intervalRef = useRef<number | null>(null);  // Ref to store the interval

    const submitIssue = () => {
        const data = { 'location_code': props.locationCode, 'issue_code': props.event?.event_type == 'PAYMENT_EXIT' ? 'MISSING_VEHICLE' : 'INCORRECT_PLATE', 'amount': amount['amount'], 'event': props.event }
        SupportService.insertSupportYPV(JSON.stringify(data), setLoading).subscribe((result: any) => {
        }, (error: any) => {
            handleClose()
            console.error(error)
        })
        setStep(1)
    }

    const handleClose = () => {
        // If the modal is closed manually, clear the timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setTimeLeft(5);  // Reset the time left to 5 seconds
        setStep(0)
        setAmount({ amount: 0 })
        if (keyboardChargeRef?.current) {
            keyboardChargeRef.current.clearInput('amount');
            currencyInputRef.current?.clearInput();
            keyboardChargeRef.current.clearInput('phone');
        }
        props.onClose()
    }

    const keyboardChargeLayout = {
        'default': [
            '1 2 3',
            '4 5 6',
            '7 8 9',
            '{bksp} 0 00',
            '{enter}'
        ]
    }

    const keyboardChargeDisplay = {
        '{bksp}': '<',
        '{enter}': 'Submit Issue'
    }

    const onKeyReleasedCharge = useCallback((button: any, e: MouseEvent | undefined) => {
        console.log(button)
        if (button == '{enter}') {
            e?.stopImmediatePropagation()
            submitIssue()
        } else if (button == '{bksp}') {
            keyboardChargeRef.current.setCaretPosition(amount.length)
        }
    }, [keyboardChargeRef.current])

    const keyboardButtonTheme = [
        {
            class: styles.keyboard_button_default,
            buttons: '1 2 3 4 5 6 7 8 9 0 00 {bksp}'
        },
        {
            class: styles.keyboard_button_action,
            buttons: '{enter}'
        }
    ]

    const steps = [
        <div style={{ marginTop: 50 }}>
            <CurrencyInput
                ref={currencyInputRef}
                inputName={'amount'}
                setFocusedInput={() => { }}
                rawValue={amount['amount']}
                disabled={false}
                onChange={null}
                inputValueChanged={() => { }}
            />
            <KeyboardReact
                keyboardRef={r => (keyboardChargeRef.current = r)}
                layout={keyboardChargeLayout} display={keyboardChargeDisplay}
                buttonTheme={keyboardButtonTheme}
                onKeyReleased={onKeyReleasedCharge}
                inputName={'amount'}
                onChangeAll={setAmount}
            />
        </div>,
        <div style={{ padding: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', verticalAlign: 'middle' }}>
            {loading &&
                <Spin size='large' style={{ marginTop: 75, marginBottom: 75 }} />
            }
            {!loading &&
                <>
                    <CheckCircleOutlined style={{ fontSize: 70, color: '#7ac968' }} />
                    <Typography style={{ fontSize: '24px', marginTop: 20 }}>Issue Submitted</Typography>
                    <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'red'}}>NO PAYMENT HAS BEEN TENDERED!</Typography>
                    <Button style={{ fontSize: '24px', fontWeight: 'bold', height: '80px', marginTop: 70, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}} type='primary' block onClick={handleClose}>Close
                        <Progress
                            type={'circle'}
                            percent={(timeLeft / 5) * 100}
                            strokeColor={'white'}
                            size={25}
                            showInfo={false}
                            style={{
                                position: 'absolute', // Set the position to absolute
                                right: '20px',        // Position it 20px from the right
                                top: '50%',           // Start from the middle vertically
                                transform: 'translateY(-50%)'  // Adjust to perfectly center vertically
                            }}
                        />
                    </Button>
                </>
            }
        </div>
    ]

    useEffect(() => {
        // When step is set to 1, start a 5-second timer to close the modal
        if (step === 1) {
            timerRef.current = window.setTimeout(() => {
                handleClose();
            }, 5000);

            // When step is set to 1, start a timer to decrement timeLeft every second
            if (step === 1) {
                intervalRef.current = window.setInterval(() => {
                    setTimeLeft(prevTime => prevTime - 1);
                }, 1000);
            }
        }
        return () => {
            // Cleanup - clear the timer when the component is unmounted or if step changes
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            // Cleanup - clear the interval when the component is unmounted or if step changes
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [step]);

    return (
        <Modal
            centered
            open={props.open}
            onCancel={handleClose}
            width={'100%'}
            bodyStyle={{height: 550}}
            footer={[]}
        >
            {steps[step]}
        </Modal>
    );
};

export default IssueModal;
