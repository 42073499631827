import GeneralService from "./GeneralService";
import { Component } from "react";


export class CommandsService extends Component {

    public static sendVehicleCommand(body: string, loadingHandler: any) {
        return GeneralService.post('/ws/ypv_command', body, loadingHandler, JSON);
    }

}