import GeneralService from "./GeneralService";
import { Component } from "react";

export class PaymentsService extends Component {

    public static processVehiclePayment(body: string, loadingHandler: any) {
        return GeneralService.post('/payments/process_vehicle_payment', body, loadingHandler, JSON);
    }

    public static processVehicleLoyalty(body: string, loadingHandler: any) {
        return GeneralService.post('/payments/process_vehicle_loyalty', body, loadingHandler, JSON);
    }

    public static enrollVehicle(body: string, loadingHandler: any) {
        return GeneralService.post('/payments/enroll_vehicle', body, loadingHandler, JSON);
    }

}