import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import {Input} from 'antd';


interface PhoneNumberInputProps {
    onChange: any;
    rawValue: any;
    inputName: any;
    disabled: any;
    setFocusedInput: any;
}
interface PhoneNumberInputHandles {
    clearInput: () => void;
}

const PhoneNumberInput = forwardRef<PhoneNumberInputHandles, PhoneNumberInputProps>((props: any, ref) => {
    const [input, setInput] = useState('');
    const placeholder = '(___) ___-____'

    useImperativeHandle(ref, () => ({
        clearInput() {
            setInput(placeholder);
        }
    }));


    // const handleChange = (e: any) => {
    //     const {value: inputValue} = e.target;
    //     const rawNumber = inputValue.replace(/[^0-9]/g, '');  // Remove any non-number character
    //     if (rawNumber.length > 10) {
    //         return;
    //     }
    //     props.setRawValue(rawNumber);
    // };

    const handleFocus = (e: any) => {
        console.log(`Handling focus in PhoneNumberInput`)
        props.setFocusedInput(props.inputName)
    }

    useEffect(() => {
        console.log(`PhoneNumberInput rawValue changed: ${props.rawValue}`)
        const placeholderList = placeholder.split('');
        const rawStr = props.rawValue.toString()
        const rawCharList = rawStr.split('');
        let charIndex = 0;
        for (let i = 0; i < placeholderList.length; i++) {
            if (placeholderList[i] == '_') {
                if (charIndex < rawCharList.length) {
                    placeholderList[i] = rawCharList[charIndex];
                }
                charIndex += 1;
            }
        }
        const formattedNumber = placeholderList.join('');

        setInput(formattedNumber);
    }, [props.rawValue])


    return (
        <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <Input
                id={props.inputName}
                size={"large"}
                disabled={props.disabled}
                value={input}
                onChange={props.onChange}
                onFocus={handleFocus}
                style={{fontSize: '48px', fontWeight: 'bold', opacity: 1}}
                readOnly
            />
        </div>
    );
});

export default PhoneNumberInput;